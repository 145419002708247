import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const Register = () => {
    const [formData, setFormData] = useState({
        ownerName: '',
        restaurantName: '',
        restaurantPhoneNumber: '',
        email: '',
        password: '',
        accountNumber: '',
        accountTitle: '',
        accountBank: '',
        restaurantAddress: '',
        restaurantLongitude: '',
        restaurantLatitude: '',
        restaurantCity: '',
        category: '',
        restaurantLogo: null,
        contractFile: null,
    });

    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();
    const [isUploadingLogo, setIsUploadingLogo] = useState(false);
    const [isUploadingContract, setIsUploadingContract] = useState(false);
    const [restaurantLogoUrl, setRestaurantLogoUrl] = useState('');
    const [contractFileUrl, setContractFileUrl] = useState('');
    const [markerPosition, setMarkerPosition] = useState({ lat: 71, lng: 31 });
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const googleMapsApiKey = "AIzaSyDJs2w7V3KzUjb5ekDkd9AWBqwbUgWq2yk";

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://foodserver.ashhkaro.com/common/category/getAllCategories');
                setCategories(response|| []);
            } catch (error) {
                console.error('Error fetching categories:', error);
                toast.error('Failed to load categories.');
            }
        };

        const getLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { longitude, latitude } = position.coords;
                        setMarkerPosition({ lat: latitude, lng: longitude });
                        setMapCenter({ lat: latitude, lng: longitude });
                        setFormData((prevData) => ({
                            ...prevData,
                            restaurantLongitude: longitude,
                            restaurantLatitude: latitude,
                        }));
                        fetchAddressFromLatLng(latitude, longitude);
                    },
                    (error) => {
                        console.error('Error getting location:', error);
                        toast.error('Could not retrieve your location. Please check your location settings.');
                        const defaultLocation = { lat: 40.712776, lng: -74.005974 }; // New York City
                        setMarkerPosition(defaultLocation);
                        setMapCenter(defaultLocation);
                        fetchAddressFromLatLng(defaultLocation.lat, defaultLocation.lng);
                    }
                );
            } else {
                console.log('Geolocation is not supported by this browser.');
                const defaultLocation = { lat: 40.712776, lng: -74.005974 }; // New York City
                setMarkerPosition(defaultLocation);
                setMapCenter(defaultLocation);
                fetchAddressFromLatLng(defaultLocation.lat, defaultLocation.lng);
            }
        };

        fetchCategories();
        getLocation();
    }, []);
    const fetchAddressFromLatLng = async (lat, lng) => {
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapsApiKey}`);
            if (response?.results?.length > 0) {
                const address = response.results[0].formatted_address;
                setFormData((prevData) => ({
                    ...prevData,
                    restaurantAddress: address,
                }));
            } else {
                toast.error('Could not retrieve address for this location.');
            }
        } catch (error) {
            console.error('Geocoding API error:', error);
            toast.error('Could not retrieve address for this location.');
        }
    };

    const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await axios.post('https://foodserver.ashhkaro.com/common/auth/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data; // Assuming the response returns the URL in this format
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error('File upload failed. Please try again.');
            return null;
        }
    };
  

        const handleChange = async (e) => {
            const { name, type, files } = e.target;
            
            if (type === 'file') {
                const file = files[0];
                if (file) {
                    // Upload the file and get the URL
                    const fileUrl = await uploadFile(file);
                    if (fileUrl) {
                        if (name === 'restaurantLogo') {
                            setRestaurantLogoUrl(fileUrl); // Set restaurant logo URL
                        } else if (name === 'contractFile') {
                            setContractFileUrl(fileUrl); // Set contract file URL
                        }
                        setFormData((prevData) => ({
                            ...prevData,
                            [name]: file, // Keep the file reference if needed
                        }));
                    } else {
                        // If the upload fails, clear the field
                        if (name === 'restaurantLogo') {
                            setRestaurantLogoUrl(''); // Clear restaurant logo URL
                        } else if (name === 'contractFile') {
                            setContractFileUrl(''); // Clear contract file URL
                        }
                        setFormData((prevData) => ({
                            ...prevData,
                            [name]: null,
                        }));
                    }
                }
            } else {
                const value = e.target.value;
                setFormData((prevData) => ({ ...prevData, [name]: value }));
            }
        };
    
    
 
    const validateForm = () => {
        const newErrors = {};
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setMarkerPosition({ lat, lng });
        setFormData((prevData) => ({
            ...prevData,
            restaurantLongitude: lng,
            restaurantLatitude: lat,
        }));

        await fetchAddressFromLatLng(lat, lng);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        setIsLoading(true);
        try {
            if ((formData.restaurantLogo && !restaurantLogoUrl) || (formData.contractFile && !contractFileUrl)) {
                toast.error('Please upload both the restaurant logo and contract file.');
                setIsLoading(false);
                return;
            }
    
            const dataToSubmit = {
                ...formData,
                restaurantLogo: restaurantLogoUrl,
                contractFile: contractFileUrl,
            };
    
            // Make the API call to create the restaurant
            const response = await axios.post('https://foodserver.ashhkaro.com/common/restaurant/createRestaurant', dataToSubmit, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
    
                // If login is successful (or registration success)
                toast.success('Registration successful!');
                setFormData({
                    ownerName: '',
                    restaurantName: '',
                    restaurantPhoneNumber: '',
                    email: '',
                    password: '',
                    accountNumber: '',
                    accountTitle: '',
                    accountBank: '',
                    restaurantLogo: null,
                    contractFile: null,
                    category: '',
                    restaurantAddress: '',
                    restaurantCity: '',
                    restaurantLongitude: '',
                    restaurantLatitude: '',
                });
                setRestaurantLogoUrl(''); // Reset URL states
                setContractFileUrl('');
    
                setTimeout(() => {
                    navigate(`/auth-login`);
                }, 5000);
            
        } catch (error) {
    
        
                if (error==="Request failed with status code 409") {
                    toast.error('Restaurant with this phone number already exists');
                }  else {
                    toast.error('An error occurred. Please try again.');
                }

        } finally {
            setIsLoading(false);
        }
    };
    
    
    
    

    return (
        <div className="bg-pattern">
            <div className="bg-overlay"></div>
            <div className="account-pages pt-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8} md={10} xl={8}>
                            <Card className='mt-5'>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <Link to="/">
                                            <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                                            <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                                        </Link>
                                    </div>
                                    <h4 className="font-size-18 text-muted text-center mt-2">Create an Account</h4>
                                    <p className="text-muted mb-4">Get your restaurant up and running in a few minutes.</p>
                                    <ToastContainer />
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="ownerName">Owner Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.ownerName ? 'is-invalid' : ''}`}
                                                        id="ownerName"
                                                        name="ownerName"
                                                        value={formData.ownerName}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.ownerName && <div className="invalid-feedback">{errors.ownerName}</div>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="restaurantName">Restaurant Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.restaurantName ? 'is-invalid' : ''}`}
                                                        id="restaurantName"
                                                        name="restaurantName"
                                                        value={formData.restaurantName}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.restaurantName && <div className="invalid-feedback">{errors.restaurantName}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md={6}>
            <div className="mb-3">
                <label className="mb-2" htmlFor="restaurantPhoneNumber">Phone Number</label>
                <PhoneInput
                    country={'us'} // Set default country if needed
                    value={formData.restaurantPhoneNumber}
                    onChange={(value) => handleChange({ target: { name: 'restaurantPhoneNumber', value } })}
                    inputStyle={{
                        width: '100%',
                        height:'37px',
                        borderRadius: '5px',
                        border: errors.restaurantPhoneNumber ? '1px solid #eff2f7' : '1px solid #eff2f7'
                    }}
                    containerStyle={{
                        marginBottom: '1rem' // To match with Bootstrap spacing
                    }}
                />
                {errors.restaurantPhoneNumber && (
                    <div className="invalid-feedback">{errors.restaurantPhoneNumber}</div>
                )}
            </div>
        </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="email">Email</label>
                                                    <input
                                                        type="email"
                                                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                        id="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="password">Password</label>
                                                    <input
                                                        type="password"
                                                        className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                        id="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="accountNumber">Account Number</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
                                                        id="accountNumber"
                                                        name="accountNumber"
                                                        value={formData.accountNumber}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.accountNumber && <div className="invalid-feedback">{errors.accountNumber}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="accountTitle">Account Title</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.accountTitle ? 'is-invalid' : ''}`}
                                                        id="accountTitle"
                                                        name="accountTitle"
                                                        value={formData.accountTitle}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.accountTitle && <div className="invalid-feedback">{errors.accountTitle}</div>}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="accountBank">Bank Name</label>
                                                    <input
                                                        type="text"
                                                        className={`form-control ${errors.accountBank ? 'is-invalid' : ''}`}
                                                        id="accountBank"
                                                        name="accountBank"
                                                        value={formData.accountBank}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.accountBank && <div className="invalid-feedback">{errors.accountBank}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                        <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="contractFile">Contract File (Upload: food dashboards.docx)</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="contractFile"
                                                        name="contractFile"
                                                        accept=".docx"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                        <Col md={6}>
                                        <div className="text-center" style={{ marginTop: '28px' }}>
    <a 
        className="btn btn-primary w-100" 
        href="./food dashboards.docx" 
        download 
        target="_blank" 
        rel="noopener noreferrer"
    >
        Download Contract
    </a>
</div>
                                        </Col>
                                            
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="restaurantLongitude">Longitude</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="restaurantLongitude"
                                                        name="restaurantLongitude"
                                                        value={formData.restaurantLongitude}
                                                        
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="restaurantLatitude">Latitude</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="restaurantLatitude"
                                                        name="restaurantLatitude"
                                                        value={formData.restaurantLatitude}
                                                        
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <div className="mb-3">
                                                    <label htmlFor="restaurantLogo">Restaurant Logo</label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        id="restaurantLogo"
                                                        name="restaurantLogo"
                                                        accept=".jpg,.jpeg,.png"
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={6}>
        <div className="mb-3">
            <label htmlFor="city">City</label>
            <input
                type="text" // Use 'text' as the input type for city
                className={`form-control ${errors.restaurantCity ? 'is-invalid' : ''}`} // Change error reference to 'city'
                id="city" // Set id for the input
                name="restaurantCity" // Set the name for the input
                value={formData.restaurantCity || ''} // Bind the city value from formData
                onChange={handleChange} // Attach the handleChange function
            />
            {errors.restaurantCity && <div className="invalid-feedback">{errors.restaurantCity}</div>}
        </div>
                                        </Col>
                                            
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label htmlFor="category">Category</label>
                                                    <select
                                                        className={`form-control ${errors.category ? 'is-invalid' : ''}`}
                                                        id="category"
                                                        name="category"
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select category</option>
                                                        {categories.map((category) => (
                                                            <option key={category._id} value={category._id}>{category.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <label htmlFor="restaurantAddress">Restaurant Address</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="restaurantAddress"
                                                        name="restaurantAddress"
                                                        value={formData.restaurantAddress}
                                                        readOnly
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <LoadScript googleMapsApiKey={googleMapsApiKey}>
                                                <GoogleMap
                                                    mapContainerStyle={{ height: "400px", width: "100%" }}
                                                    center={mapCenter}
                                                    zoom={10}
                                                    onClick={handleMapClick}
                                                >
                                                    <Marker position={markerPosition} />
                                                </GoogleMap>
                                            </LoadScript>
                                        </Row>
                                        <div className="text-center mt-4">
                                            <button type="submit" className="btn btn-primary w-100" disabled={isLoading || isUploadingLogo || isUploadingContract}>
                                                {isLoading ? <Spinner size="sm" /> : 'Register'}
                                            </button>
                                        </div>
                                        <div className="text-center mt-2">
                                            <p className="text-muted">Already have an account? <Link to="/auth-login" className="text-primary">Login</Link></p>
                                        </div>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default Register;
