import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import logo from '../../assets/images/ashhkaro.png'
const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  }, []);

  const validation = useFormik({
    initialValues: {
      restaurantPhoneNumber: '',
      password: '',
    },
    validationSchema: Yup.object({
      restaurantPhoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      setLoading(true); // Show loader
     
      try {
        const response = await axios.post('https://foodserver.ashhkaro.com/common/restaurant/login', {
          restaurantPhoneNumber:values.restaurantPhoneNumber,
          password: values.password,
        });
        if (response.message === 'Login successful') {
          if (response.paymentStatus !== true) {
            toast.error("Login failed. Payment status is inactive."); // Show error message
            
            return; 
          }
          if (response.status !== true) {
            toast.error("Login failed. Your account status is inactive."); 
            return; 
          }

          toast.success("Login successful!"); // Show success toast
          localStorage.setItem('restaurant', JSON.stringify(response));
          setTimeout(() => {
            navigate(`/pages-starter/${response.restaurantId}`);
          }, 2000);
        } 

      } catch (error) {
        console.log(error)
        if (error=== 'Invalid credentials') {
          toast.error("Login failed. Please check your credentials."); 
        } else {
          toast.error("An error occurred. Please try again."); 
        }
      } finally {
        setLoading(false); 
      }
    }
  });

  const backgroundStyle = {
    backgroundImage: "url('/path/to/your/background-image.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div style={backgroundStyle}>
        <div className="bg-overlay"></div>
        <div className="account-pages my-5 pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={4}>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center">
                      <Link to="/">
                        <img src={logo} alt="" height="40" className=" logo-dark mx-auto" />
                        <img src={logo} alt="" height="40" className=" logo-light mx-auto" />
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">Welcome Back!</h4>
                    <p className="mb-5 text-center">Sign in to continue.</p>
                    <Form onSubmit={validation.handleSubmit}>
                      {validation.errors.global && <Alert color="danger"><div>{validation.errors.global}</div></Alert>}
                      <Row>
                        <Col md={12} >
                        <div className="mb-4 border-none">
      <Label className="form-label">Phone Number</Label>
      <PhoneInput
        country={'us'} // Set default country code here, if needed
        name="restaurantPhoneNumber"
        className={`form-control ${validation.touched.restaurantPhoneNumber && validation.errors.restaurantPhoneNumber ? 'is-invalid' : ''}`}
        placeholder="Enter Phone Number"
        inputStyle={{
          width: '100%',
          height:'30px',
          paddingLeft:'-12px',
          borderRadius: '5px',border:'none'
      }}
        onChange={(phone) => validation.setFieldValue('restaurantPhoneNumber', phone)}
        onBlur={validation.handleBlur}
        value={validation.values.restaurantPhoneNumber}
      />
      {validation.touched.restaurantPhoneNumber && validation.errors.restaurantPhoneNumber ? (
        <FormFeedback type="invalid">
          <div>{validation.errors.restaurantPhoneNumber}</div>
        </FormFeedback>
      ) : null}
                            </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password}
                              invalid={validation.touched.password && validation.errors.password ? true : false}
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="d-grid mt-4">
                            <button className="btn btn-primary waves-effect waves-light" type="submit" disabled={loading}>
                              {loading ? "Loading..." : "Log In"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="text-white-50">Don't have an account? <Link to="/auth-register" className="fw-medium text-primary">Register</Link></p>
                  <p className="text-white-50">© {new Date().getFullYear()} Your App Name. Crafted with <i className="mdi mdi-heart text-danger"></i> by Your Company</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
